import React from "react";
import NavBar from "./Layout/NavBar";

const Help = () => {


    return(
        <div>
            <NavBar />
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div>
               <h1>Help</h1>
               <br/>
               <br/>
                <p>If you run into any technical issues while using this website, please contact cityrunnerltd@gmail.com</p>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
               
            </div>
        </div>
    )
}

export default Help;
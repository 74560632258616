import React from "react";
import {Link} from "react-router-dom";


const Success = () => {
    return(
        <div >
            
                <div className="successDiv">
            <h1 className="success-page"> Shipment request received!!  </h1>
            <h2 className="success-page-2"> Ship again?</h2>
            <br/>
            <br/>
            <br />

            </div>
        </div>
    )
}

export default Success
import React from "react";
import NavBar from "../NavBar";

const WhoWeAre = () => {
    return(
        <div>
            <NavBar />
            <div className="whoweare-div" id="toppage1">
                <h1 className="whoweare-h1">Who we are</h1>

            </div>

            <div className="important-div">
            <div className="wwaa">
                <h1  className="wwa-h1">Mission</h1>
                <br/>
                <p>To become Nigeria’s most preferred supplied logistic company.</p>
            </div>
            <div className="wwaa">
                <h1  className="wwa-h1">Core Values</h1>
                <br/>
                <p>Trust, Safety, Speed.</p>
            </div>
            <div className="wwaa">
                <h1  className="wwa-h1">Vision</h1>
                <br/>
                <p>To offer the best delivery experience across Nigeria.</p>
            </div>
            <div className="wwaa">
                <h1  className="wwa-h1">About Us</h1>
                <br/>
                <p className="wwa-body">City Runner is a registered and licensed Logistics Services Company based in Lagos. Our customers and potential customers alike can rest assured that they will get quality services at competitive rates. We go the extra mile to ensure the safety of goods under our care and our customers get value for their money. At City Runner, our goal is to provide excellent professional service to our customers, and we pride ourselves on the integrity and competence of our company and our employees.
Our clients are Corporate Organizations and SMEs who wish to dispatch their goods to their customers, domestic door-to-door deliveries for individuals. Our partners are merchants incorporated on our platform, from which customers can shop directly.
                </p>
    
            </div>

            </div>
           

            </div>
    )
}

export default WhoWeAre;
import React from "react";
import googleplay from "../../assets/googleplay.png";
import appstore from "../../assets/appstore.jpg";
import twitter from "../../assets/twitter.svg";
import instagram from "../../assets/instagram.svg";
import facebook from "../../assets/facebook.svg";
import linkedin from "../../assets/linkedin.svg";
import { Link } from "react-router-dom";
import Logo2 from "../../assets/logo-4.png";

const Footer = () => {


    return(
        <div className="footer">
            
            <div className="grid-container">

                <div className="footer-company-logo footer-div extragrid">
                <Link to="/"><img src={Logo2} alt="logo2" className="logo2"/></Link>
                </div>

                <div className="footer-div">
                    <h3 className="footerh3">About</h3>
                    <a href="https://www.cityrunnerltd.com/whoweare" className="normal"><p className="footer-p">Who we are</p></a>
                    <a href="https://www.cityrunnerltd.com/signupuserorrider" className="normal"><p className="footer-p">Careers</p></a>
                    <a href="https://www.cityrunnerltd.com/prices" className="normal"><p className="footer-p">Pricing</p></a>
                    <a href="https://www.cityrunnerltd.com/faqs/" className="normal"><p className="footer-p">FAQs</p></a>
                    <a href="https://www.cityrunnerltd.com/terms" className="normal"><p className="footer-p">Terms</p></a>
                    <a href="https://www.cityrunnerltd.com/privacypolicy" className="normal"><p className="footer-p">Privacy Policy</p></a>
                    <a href="https://www.cityrunnerltd.com/shippingpolicy" className="normal"><p className="footer-p">Shipping Policy</p></a>
                </div>

                <div className="footer-div">
                <h3 className="footerh3">Company</h3>
                <a href="https://www.cityrunnerltd.com/signupuserorrider" className="normal" ><p className="footer-p">Become a Runner</p></a>
                <a href="https://www.cityrunnerltd.com/becomeapartner" className="normal" ><p className="footer-p">Become a Partner</p></a>
                <a href="https://medium.com/@cityrunnerltdblog" className="normal" ><p className="footer-p">Blog</p></a>
                <a href="https://www.google.com/search?q=cityrunner+delivery+Nigeria&sxsrf=ALeKk03Gi0gL_zn6E5geBZHV_BZENyIkTQ:1608248449939&source=lnms&sa=X&ved=0ahUKEwizm8asmNbtAhVPTcAKHbEBDdsQ_AUIDigA&biw=1280&bih=721&dpr=2" className="normal"><p className="footer-p">Media & Press</p></a>
                <a href="https://wa.me/message/R7XEYJKSMQ6CD1" className="normal" ><p className="footer-p">WhatsApp Chat</p></a>
                <a href="https://forms.gle/THUEkJfMVnammoLF8" className="normal"><p className="footer-p">Google Form</p></a>
               
                    
                </div> 

                <div className="extragrid">
                    <p className="invincible"></p>
                </div>

                <div className="footer-div">
                <h3 className="footerh3">Contact Us</h3>
                <a href="mailto:info@cityrunnerltd.com?subject=CONTACT MAIL FROM CITYRUNNER WEBSITE"className="normal" target="_blank" rel="noopener noreferrer"><p className="footer-p">info@cityrunnerltd.com</p></a>
                <a href="tel:+2348093963988"className="normal" target="_blank" rel="noopener noreferrer"><p className="footer-p">(+234) 809 396 3988</p></a> 
                <a href="https://www.google.com/maps/dir//235+Ikorodu+Rd,+Ilupeju,+Lagos/@6.5537929,3.363805,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x103b8d91a80457c1:0xaa165bec2d0c8077!2m2!1d3.3659937!2d6.5537876"className="normal" target="_blank" rel="noopener noreferrer"><p className="footer-p">235 Ikorodu Road, Ilupeju, Lagos</p></a>                    
                   
                </div> 

                <div className="footericons footer-div">
                    <div className="mobileapps">
                    <a href="https://apps.apple.com/app/cityrunner-ltd/id1597361637"  className="normal"  target="_blank" rel="noopener noreferrer"><img className="footericon1" src={appstore} alt="appstore"/></a>
                    <a href="https://play.google.com/store/apps/details?id=com.omoruyiohuoba.CITYRUNNERMOBILE"  className="normal"  target="_blank" rel="noopener noreferrer"><img className="footericon1" src={googleplay} alt="googleplay"/></a>
                    </div>
                    <div className="iconss">
                    <a href="https://www.facebook.com/CityRunner-106458851238175/?_rdc=1&_rdr" className="normal"  target="_blank" rel="noopener noreferrer"><img className="footericon2" src={facebook} alt="facebook"/></a>
                    <a href="https://twitter.com/cityrunnerng/" className="normal"  target="_blank" rel="noopener noreferrer"><img className="footericon2" src={twitter} alt="twitter"/></a>
                    <a href="https://www.instagram.com/cityrunnerng/" className="normal"  target="_blank" rel="noopener noreferrer"><img className="footericon2" src={instagram} alt="instagram"/></a>
                    <a href="https://www.linkedin.com/company/cityrunnerng/" className="normal"  target="_blank" rel="noopener noreferrer"><img className="footericon2" src={linkedin} alt="linkedin"/></a>
                    </div>
                   
                </div>
            </div>

            <div className="below-footer">
            <p className="last">© {new Date().getFullYear()} CityRunner Ltd. All Rights Reserved.</p>            </div>

        </div>
    )
}

export default Footer;